import React, { useCallback, useState } from "react";
import { AlertProvider } from "./AlertContext";
import { AlertPopup } from "./AlertPopup";

export const AlertContainer = ({ children }) => {
  const [alertProps, setAlertProps] = useState();

  const invokeAlert = useCallback(type => {
    return ({ title = "", message = "", confirmText = "", rateandConfirm = false, status = "", acceptAction }) => {
      setAlertProps({
        title,
        message,
        confirmText,
        acceptAction,
        type,
        rateandConfirm,
        status,
      });
    };
  }, []);

  const closeHandler = () => {
    setAlertProps();
  };

  return (
    <AlertProvider value={invokeAlert}>
      {alertProps && <AlertPopup closeHandler={closeHandler} alertProps={alertProps} />}{" "}
      <React.Fragment>{children}</React.Fragment>
    </AlertProvider>
  );
};
