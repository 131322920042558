import React, { useState } from "react";
import styled from "styled-components";
import { INPUT_STATUS } from "../../shared/consts";

import "./Alert.css";

import Button from "../Button-Component/Button";
import { palette } from "../colors";
import Popper from "../Popper";
import { ALERT_TYPE } from "../../consts";
import "../NewAlert-Component/Rating.css";
import EditableStarRating from "../../components-app/uiComponents/EditableStarRating";

export const AlertPopup = ({ closeHandler, alertProps = {} }) => {
  const { title, message, confirmText, type, acceptAction, rateandConfirm, status } = alertProps;
  const [text, setText] = useState("");
  const [ratingSelected, setratingSelected] = useState("");
  const stageRated =
    status === INPUT_STATUS.PEER_AUDIO_REVIEW ? INPUT_STATUS.QUICKFIRE : INPUT_STATUS.PEER_AUDIO_REVIEW;

  const actionHandler = () => {
    if (type === ALERT_TYPE.PROMPT) {
      if (text && text === confirmText) {
        acceptAction(closeHandler);
      }
    } else if (type === ALERT_TYPE.CONFIRM) {
      acceptAction && acceptAction(closeHandler, ratingSelected);
    } else {
      closeHandler();
    }
  };
  const StarRatingPopUp = () => {
    return (
      <EditableStarRating
        stageRated={stageRated}
        ratingSelected={ratingSelected}
        setratingSelected={setratingSelected}
      />
    );
  };

  return (
    <Popper
      closeHandler={closeHandler}
      title={rateandConfirm && ratingSelected == "" ? `Please rate the quality of ${stageRated}` : title}
    >
      <Container>
        <HorizontalLine></HorizontalLine>
        {message && (
          <div>
            {((rateandConfirm && ratingSelected != "") || !rateandConfirm) && <BodyInfo>{message}</BodyInfo>}
            {rateandConfirm && ratingSelected != "" && <p>Rating Given {ratingSelected}</p>}
          </div>
        )}
        {rateandConfirm && ratingSelected === "" && <StarRatingPopUp />}

        {type === ALERT_TYPE.PROMPT && <Input onChange={e => setText(e.target.value)} value={text} />}
        {((rateandConfirm && ratingSelected != "") || !rateandConfirm) && (
          <ButtonSection>
            {(type === ALERT_TYPE.CONFIRM || type === ALERT_TYPE.PROMPT) && (
              <Button size="sm" underline onClick={closeHandler}>
                Cancel
              </Button>
            )}
            <Button size="sm" onClick={actionHandler} style={{ marginLeft: 10 }}>
              {type === ALERT_TYPE.ALERT ? "Ok" : "Accept"}
            </Button>
          </ButtonSection>
        )}
      </Container>
    </Popper>
  );
};

const Container = styled.div`
  width: 500px;
`;

const Input = styled.input`
  height: 30px;
  font-size: 18px;
  width: 95%;
  outline: none;
  border-radius: 5px;
  padding: 0px 10px;
  margin-bottom: 10px;
`;

const HorizontalLine = styled.div`
  border: 0.2px solid #dddddd;
  height: 0px;
  margin: 10px 0px;
`;

const BodyInfo = styled.p`
  color: ${palette.PRIMARY};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
`;
