import { getServerUrl } from "./env";

export const LEGACY_IS_HARDER_THAN_STRIKETHROUGH_ORDER_BY_THIS_MANY_TIMES = 4;

export const ORDER_TYPES = {
  LEGACY: "legacy",
  STRIKETHROUGH: "strikethrough",
  HIPAA: "hipaa",
  UNRECOGNIZED: "unrecognized",
};

export const ORDER_STATUS = {
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
};

export const INPUT_STATUS = {
  MACHINE_TRANSCRIPT: "Machine Transcript",
  QUICKFIRE: "Quickfire",
  PEER_TEXT_REVIEW: "Peer Text Review",
  PEER_AUDIO_REVIEW: "Peer Audio Review",
  SECOND_REVIEW: "Second Review",
  COMPLETED: "Completed",
  LIMBO: "Limbo",
};
const punctuations = ["!", "?", ",", ";", ":", ".", "/", "@", "-", "_", "'", "*", "{", "}"];

export const EXTRA_WEIGHT_OF_PUNCTUATION = 2;

export const isTherePunctuationInText = text => {
  const punctionArray = punctuations.filter(el => text.includes(el));

  if (punctionArray.length) return true;
};

export const STRIKETHROUGH_INPUT_STATUS = "Strikethrough";

export const INPUT_STATUS_WITH_STRIKETHROUGH = {
  ...INPUT_STATUS,
  STRIKETHROUGH: STRIKETHROUGH_INPUT_STATUS,
};

export const REALMS = {
  ORDER: "order",
  INPUT: "input",
  ATTACHMENT: "attachment",
  USER: "user",
  REDUCT_DOC: "reduct-doc",
  WAREHOUSE_ORDER: "warehouse/_order",
  WAREHOUSE_INPUT: "warehouse/_input",
  PREORDER: "preorder",
  SYSTEM_META: "systemMeta",
  LOG: "log",
};

export const SYSTEM_META_FIELDS = {
  FEATURES: "features",
};

export const FEATURES = {
  MACHINE_TRANSCRIPT_OPTION: "Machine transcript option",
  ENFORCE_TEXT_REVIEW: "Enforce Text Review",
  ENFORCE_SECOND_REVIEW: "Enforce Seconds Review",
  SURGE_MODE: "Surge Mode",
  CLAIM_POOL: "Claim Pool",
};

export const fillerWords = ["Um", "um", "Ah", "Uh", "uh", "Uh,", "Um,", "um,", "Ah,", "uh,"];

export const SERVER_URL = getServerUrl(process.env);

export const ALIGNMENT_STATUS = {
  ALIGNED: "Aligned",
  ALIGNING: "Aligning",
  NOT_ALIGNED: "Not Aligned",
};

export const SORT_ORDER = {
  ASCENDING: "Ascending",
  DESCENDING: "Descending",
};

export const GOOGLE_CONSOLE = {
  BUCKET_NAME: "reduct-whatsay",
};

// Shared palette
export const palette = {
  WHITE: "#FFFFFF",
  BORDER_COLOR: "#ECEFF1",
  GHOST_TEXT: "#CFD8DC",
  SUBTLE_TEXT: "#90A4AE",
  MID_TEXT: "#607D8B",
  DARK_TEXT: "#263238",
  TEAL_LIGHT: "#A2E7D6",
  TEAL: "#16C399",
  TEAL_DARK: "#27AE91",

  SECONDARY_LIGHT_PINK: "#F5B7AB",
  SECONDARY_DARK_PINK: "#EE836E",
  ERROR_RED: "rgb(219,58,27)",
  ERROR_RED_BACKGROUND: "rgba(219,58,27, 0.2)",
  HIGHLIGHT_YELLOW: "#FFE8AA",
  SECONDARY_DARK_YELLOW: "#FEDE88",
  SECONDARY_LIGHT_GREEN: "#C5E5DE",
  SECONDARY_DARK_GREEN: "#72C1B0",
  SECONDARY_LIGHT_BLUE: "#B1E0EC",
  SECONDARY_DARK_BLUE: "#6DC5DC",

  ERROR_YELLOW_BACKGROUND: "#fffad1",
  BACKGROUND_TEAL: "rgba(22, 195, 153, .7)",
  BACKGROUND_TEAL_DIM: "rgba(22, 195, 153, .4)",
  BACKGROUND_TEAL_LIGHT: "rgba(22, 195, 153, .1)",
};

// Alternative palette
export const altPalette = {
  PRIMARY_BACKGROUND: "#1C3D47",
  SECONDARY_BACKGROUND: "#275361",

  PRIMARY_LIGHT_BACKGROUND: "#316E82",
  PRIMARY_WHITE: "#DFDEDE",
  PRIMARY_BLACK: "#2D2B2B",
  LIGHT_GRAY: "#C4C4C4",
};

// Shared font sizes
export const fontSizes = {
  H1: "32px",
  H2: "28px",
  H3: "24px",
  H4: "20px",
  H5: "16px",
  H6: "14px",
  P: "16px",
  Small: "12px",
  Tiny: "10px",
  TagText: "12px",
};

export const fontWeights = {
  THIN: 300,
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 600,
  BLACK: 700,
};

export const fontFamilies = {
  PRIMARY: "Work Verdana, Geneva, Tahoma, sans-serif;",
  SECONDARY: "Roboto",
  MONOSPACE: "Lucida Console, Monaco, monospace;",
  BALOO2: "Baloo 2",
};

export const shadows = {
  ELEVATION1:
    "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
  ELEVATION2:
    "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
  ELEVATION3:
    "0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
};

export const HEADER_HEIGHT = 70;
export const HIGHLIGHT_PAGE_VIDEO_WIDTH = 680;
export const HIGHLIGHT_PAGE_VIDEO_HEIGHT = 382;
export const HIGHLIGHT_WAVEFORM_PADDING_PX = 220; // in pixels -- how much extra to load?
export const PIXELS_PER_SECOND = 100;

export const TIMEZONE = "Asia/Kathmandu";

export const INAUDIBLE = "[inaudible]";
export const CROSSTALK = "[crosstalk]";
export const UNCLEAR = "<UNCLEAR>";

export const LOG_EVENTS = {
  STATUS: "Status",
  SAVED: "Saved",
  ASSIGNEE_CHANGE: "Assignee Change",
  TRANSFER_USER: "transfer",
  COMPLETE_STAGE: "Stage Complete",
  UNCLAIM: "unclaimed",
  TEXTPASTED: "textpasted",
};
export const VALID_COUNTRIES = ["NP"];

export const TAX_RATE = 0.15; //15% tax rate

export const FINISHED = "Finished";
