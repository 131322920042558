import React, { useCallback, useContext, useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";

import "./App.css";
import "./css/font-awesome.min.css";

// Public pages
const GoogleLogin = lazy(() => import("./components-app/GoogleLogin"));
const RegisterSteps = lazy(() => import("./components-app/register/RegisterSteps"));
const GoogleSignUp = lazy(() => import("./components-app/GoogleSignup"));
const RegisterSuccess = lazy(() => import("./components-app/register/RegisterSuccess"));
const DownloadElectron = lazy(() => import("./components-app/login/DownloadElectron"));
const OfflineLogin = lazy(() => import("./components-app/OfflineLogin"));

// Auth pages

const Dashboard = lazy(() => import("./components-app/dashboard/Dashboard"));
const HomePage = lazy(() => import("./components-app/new-homePage/HomePage"));
const Payments = lazy(() => import("./components-app/dashboard/Payments"));
const Transcripts = lazy(() => import("./components-app/Transcripts"));
const NewTranscriptList = lazy(() => import("./components-app/new_transcript_list/"));
const ReductDocs = lazy(() => import("./components-app/ReductDocs"));

const Diff = lazy(() => import("./components-app/transcript/Diff"));
const MachineTxDiff = lazy(() => import("./components-app/MachineTxDiff/MachineTxDiff"));
const Strikethrough = lazy(() => import("./components-app/Strikethrough"));
const Help = lazy(() => import("./components-app/Help"));
const UserProfile = lazy(() => import("./components-app/userProfile"));
const MachineDiff = lazy(() => import("./components-app/transcript/MachineDiff"));
const SpeakerDiff = lazy(() => import("./components-app/transcript/SpeakerDiff"));
const Transcript = lazy(() => import("./components-app/transcript/Transcript"));
const NewTranscript = lazy(() => import("./components-app/new_transcript"));

// Admin pages
const AdminPage = lazy(() => import("./components-app/AdminPage"));
const ArchivedOrders = lazy(() => import("./components-app/orders/ArchivedOrders"));
const Orders = lazy(() => import("./components-app/orders/Orders"));
const Users = lazy(() => import("./components-app/Users"));
const Register = lazy(() => import("./components-app/register/Register"));
const NewOrder = lazy(() => import("./components-app/orders/NewOrder"));
const Features = lazy(() => import("./components-app/Features"));
const SecondReviews = lazy(() => import("./components-app/SecondReviews"));
const UserData = lazy(() => import("./components-app/user/UserData"));
const NavBar = lazy(() => import("./components-app/uiComponents/Navbar"));
const BatchAction = lazy(() => import("./components-app/batch_actions"));
const UserStats = lazy(() => import("./components-app/user_stats/index"));

// components and api calls
const Header = lazy(() => import("./Header"));
const ElectronLogin = lazy(() => import("./components-app/electron/Login"));
const Loading = lazy(() => import("./components-app/generalComponents/Loading"));
const LockScreen = lazy(() => import("./components-app/generalComponents/LockScreen"));
const SetUpPin = lazy(() => import("./components-app/generalComponents/SetUpPin"));
const WhatsayPasteAttempt = lazy(() => import("./components-app/whatsayPasteAttempt"));

import { getTips } from "./api/calls";
import { UserInfoContext } from "./containers/UserInfo";
import { clearExpiredAttachments } from "./utils/caching";
import { useAlert } from "./customHooks/alertHook";
import { FeatureContext } from "./containers/Features";

const App = () => {
  const [loading, setLoading] = useState(true);
  const { isSuperUser, userInfo, fetchUserInfo, userInfoLoading } = useContext(UserInfoContext);
  const { featuresLoading } = useContext(FeatureContext);
  const alert = useAlert();

  const fetchTips = useCallback(
    async () => {
      try {
        const response = await getTips();

        window.TIPS = response.data.data.data;
      } catch (e) {
        // if fetching tips fails it means there is something wrong with the token or login so reset the login
        if (!e.response) {
          alert({
            title: "",
            message: "Error while fetching TIPS",
          });
        } else {
          alert({
            title: "",
            message: `Unable to export: ${e.response.data && e.response.data.errors}`,
          });
        }
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    const hasCredentials = localStorage.getItem("token") && localStorage.getItem("email");

    if (hasCredentials && !Object.keys(userInfo).length) {
      (async () => {
        await fetchUserInfo();
        await fetchTips();
        clearExpiredAttachments();
        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  }, [fetchUserInfo, userInfo, fetchTips]);

  return (
    <Suspense fallback={<span>Loading..</span>}>
      {loading || userInfoLoading || featuresLoading ? (
        <Loading />
      ) : (
        <BrowserRouter>
          {userInfo && userInfo.email && <SetUpPin />}
          {userInfo && userInfo.email && <LockScreen />}

          <Switch>
            {!userInfo.email && process.env.NODE_ENV !== "development" && (
              <Route exact path="/login" component={GoogleLogin} />
            )}
            <Route exact path="/registersteps" component={RegisterSteps} />

            <Route exact path="/registersuccess" component={RegisterSuccess} />

            {userInfo.selectNewEditor && <PrivateRoute exact path="/transcript/:id" component={NewTranscript} />}

            {/** TODO: Refactor for pages without headers */}
            <LayoutWrapper>
              {isSuperUser && <NavBar />}
              <AppWrapper>
                <Header />
                <BodyWrapper>
                  <Switch>
                    {/* Public Routes */}
                    <Route exact path="/download" component={DownloadElectron} />
                    <Route exact path="/2fa" component={GoogleSignUp} />
                    {/* if user info is load it means user is already logged in so dont load either of login component for them */}
                    {!userInfo.email && process.env.NODE_ENV === "development" && (
                      <Route exact path="/login" component={OfflineLogin} />
                    )}
                    <Route exact path="/electron-login/:token/:refreshToken/:email" component={ElectronLogin} />
                    <Route exact path="/electron-login" component={ElectronLogin} />
                    {/* Private Routes */}
                    <PrivateRoute exact path="/" component={HomePage} />
                    <PrivateRoute exact path="/analytics" component={Dashboard} />
                    <PrivateRoute exact path="/dashboard" component={Payments} />
                    <PrivateRoute style={{ position: "relative" }} exact path="/transcripts" component={Transcripts} />
                    <PrivateRoute exact path="/transcript/:id" component={Transcript} />
                    <PrivateRoute exact path="/transcript/:id/strikethrough" component={Strikethrough} />
                    <PrivateRoute exact path="/transcript/:id/diff" component={Diff} />
                    <PrivateRoute exact path="/transcript/:id/machine-diff" component={MachineDiff} />
                    <PrivateRoute exact path="/transcript/:id/speaker-diff" component={SpeakerDiff} />
                    <PrivateRoute exact path="/reduct-docs" component={ReductDocs} />
                    <PrivateRoute exact path="/help" component={Help} />
                    <PrivateRoute exact path="/profile" component={UserProfile} />
                    <PrivateRoute exact path="/reduct-docs" component={ReductDocs} />
                    <AdminRoute exact path="/orders/archived" component={ArchivedOrders} />
                    <AdminRoute exact path="/users" component={Users} />
                    <AdminRoute exact path="/features" component={Features} />
                    <AdminRoute exact path="/new-order" component={NewOrder} />
                    <AdminRoute exact path="/register" component={Register} />
                    <AdminRoute exact path="/whatsay-paste-attempt" component={WhatsayPasteAttempt} />
                    <AdminRoute exact path="/users" component={Users} />
                    <AdminRoute exact path="/features" component={Features} />
                    <AdminRoute exact path="/new-order" component={NewOrder} />
                    <AdminRoute exact path="/admin" component={AdminPage} />
                    <AdminRoute exact path="/orders" component={Orders} />
                    <AdminRoute exact path="/second-reviews" component={SecondReviews} />
                    <AdminRoute exact path="/transcript-list" component={NewTranscriptList} />
                    <AdminRoute exact path="/export-data" component={UserData} />
                    <AdminRoute exact path="/batch-actions" component={BatchAction} />
                    <AdminRoute exact path="/user_stats" component={UserStats} />
                    <AdminRoute exact path="/machine-diff" component={MachineTxDiff} />
                    <Redirect
                      to={{
                        pathname: "/",
                      }}
                    />
                  </Switch>
                </BodyWrapper>
              </AppWrapper>
            </LayoutWrapper>
          </Switch>
        </BrowserRouter>
      )}
    </Suspense>
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const hasCredentials = localStorage.getItem("token") && localStorage.getItem("email");

  if (hasCredentials) {
    return <Route {...rest}>{children}</Route>;
  }

  if (hasCredentials && !window.electronApi) {
    return (
      <Redirect
        to={{
          pathname: "/download",
        }}
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: "/login",
      }}
    />
  );
}

function AdminRoute({ children, ...rest }) {
  const hasCredentials = localStorage.getItem("token") && localStorage.getItem("email");

  const { isSuperUser } = useContext(UserInfoContext);

  if (hasCredentials && isSuperUser) {
    return <Route {...rest}>{children}</Route>;
  }

  if (hasCredentials) {
    <Redirect
      to={{
        pathname: "/",
      }}
    />;
  }

  return (
    <Redirect
      to={{
        pathname: "/login",
      }}
    />
  );
}

const AppWrapper = styled.div`
  font-family: Baloo 2;
  width: 100%;
  min-width: 80vw;
  flex: 1;
`;

const LayoutWrapper = styled.div`
  position: relative;
  display: flex;
`;

const BodyWrapper = styled.div`
  padding: 0px;
`;

export default App;
