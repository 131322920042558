import React from "react";
import styled from "styled-components";
import googleIcon from "../../SVG/googleIcon.svg";
import { palette } from "../colors";
import "./Button.css";

const ReductButton = ({
  children,
  type,
  onClick,
  iconLeft,
  iconRight,
  disabled,
  size,
  outline,
  underline,
  style = {},
}) => {
  const classNames = ["button"];

  if (size) {
    classNames.push(`button-${size}`);
  }
  if (disabled) {
    classNames.push("button-disabled");
  }
  if (outline) {
    classNames.push("button-outline");
  }
  if (underline) {
    classNames.push("button-underline");
  }

  return (
    <Btn className={classNames.join(" ")} disabled={disabled} type={type} onClick={onClick} style={style}>
      {iconLeft ? (
        <img style={{ marginRight: "0.5rem", width: "1.5rem", height: "1.5rem" }} alt="google icon" src={iconLeft} />
      ) : (
        ""
      )}
      {children}
      {iconRight ? (
        <img style={{ marginLeft: "0.5rem", width: "1.5rem", height: "1.5rem" }} alt="google icon" src={iconRight} />
      ) : (
        ""
      )}
    </Btn>
  );
};

export const Button = () => {
  return (
    <div>
      <ReductButton onClick={() => alert("Hello ")} iconLeft={googleIcon} outline>
        Button Lg
      </ReductButton>
      <ReductButton onClick={() => alert("Hello ")} iconLeft={googleIcon} outline size="sm">
        Button sm
      </ReductButton>
      <ReductButton onClick={() => alert("Hello ")} underline size="sm">
        Button
      </ReductButton>
    </div>
  );
};

const Btn = styled.button`
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  &:hover {
    transform: scale(1.1);
  }
  /**
   *  TODO: Using color variables from styled component
   * */
  // background-color: ${palette.YELLOW};
`;

export default ReductButton;
