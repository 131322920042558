import { SERVER_URL } from "../shared/consts";
import moment from "moment";

export const download = (filename, text) => {
  var element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const getCookie = key => {
  // Get all the cookies pairs in an array
  const cookiearray = document.cookie.split(";");

  const cookieItem = cookiearray.find(item => {
    const name = item.split("=")[0];
    return key === name.trim();
  });

  if (!cookieItem) {
    return "";
  }

  return cookieItem.split("=")[1];
};

export const getServerObj = () => ({
  serverUrl: SERVER_URL,
});

export const getUniqueEleArr = (array, keyForUnique) => {
  const uniqueKeys = [...new Set(array.map(val => val[keyForUnique]))];
  return uniqueKeys.map(ele => array.find(val => val[keyForUnique] === ele));
};

export const getPaymentWeek = () => {
  const todayInNum = moment().days();
  const today = moment().format("MMM DD");
  if (todayInNum > 2) {
    const gap = todayInNum - 2;
    const firstDayOfWeek = moment().subtract(gap, "days").format("MMM DD");
    return firstDayOfWeek + "-" + today;
  }
  return today;
};

export const getDiffQueryString = (id, from, to) => {
  const diffFrom = from.replace(/\s/g, "+");
  const diffTo = to.replace(/\s/g, "+");
  return `/transcript/${id}/diff?diffFrom=${diffFrom}&diffTo=${diffTo}`;
};

export class Counter {
  constructor(start) {
    this.counter = start;
    this.defaultValue = start;
  }
  getCount() {
    return this.counter;
  }
  countDown() {
    if (this.counter === 0) {
      this.counter = this.defaultValue;
      return;
    }
    this.counter--;
  }
  countUp() {
    if (this.counter > this.defaultValue) {
      this.counter = this.defaultValue;
      return;
    }
    this.counter++;
  }
}
