import { INPUT_STATUS, INPUT_STATUS_WITH_STRIKETHROUGH } from "./shared/consts";

export const MODALS = {
  ATTACHMENTS: "Attachments",
  INPUTS: "Inputs",
  ORDERS: "Orders",
  SUCCESS_MESSAGE: "Success Message",
};

export const STAGE_COLORS = {
  [INPUT_STATUS_WITH_STRIKETHROUGH.STRIKETHROUGH]: "#8ce0f2",
  [INPUT_STATUS.QUICKFIRE]: "#f28caf",
  [INPUT_STATUS.PEER_TEXT_REVIEW]: "#a68cf2",
  [INPUT_STATUS.PEER_AUDIO_REVIEW]: "#f2d78c",
  [INPUT_STATUS.SECOND_REVIEW]: "#74cf7b",
  [INPUT_STATUS.COMPLETED]: "#73DF20",
  [INPUT_STATUS.MACHINE_TRANSCRIPT]: "#FFD700",
};

export const ALERT_TYPE = {
  ALERT: "alert",
  CONFIRM: "confirm",
  PROMPT: "prompt",
};

export const ASSIGNMENT_ACTION = {
  SELECT: "preassign",
  TRANSFER: "assign",
  EDIT: "postassign",
};

export const hourInMs = 3600 * 1000;
