import React, { useCallback, useState } from "react";
import axiosClient from "../middleware/axiosClient";
import { REALMS, SERVER_URL } from "../shared/consts";

export const UserInfoContext = React.createContext();

export const WithUserInfo = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  const [isSuperUser, setSuperUser] = useState(false);
  const [userInfoLoading, setUserInfoLoading] = useState(false);

  const fetchUserInfo = useCallback(async () => {
    try {
      setUserInfoLoading(true);
      const email = localStorage.getItem("email");
      const response = await axiosClient.get(`${SERVER_URL}/${REALMS.USER}/info?email=${email}`);
      const userInfo = response.data;

      setUserInfo(userInfo);
      setSuperUser(userInfo.isSuperUser);
      setUserInfoLoading(false);
    } catch (e) {
      const errorMsg = e.response.data && e.response.data.message;

      if (errorMsg) {
        alert(errorMsg);
        if (e.response.data && e.response.data.status === 403) {
          localStorage.clear();
          window.location.href = "/login";
        }
      } else {
        alert("Unable to log in.");
      }
    }
  }, []);

  const logOut = () => {
    setUserInfo({});
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <UserInfoContext.Provider
      value={{
        userInfo,
        setUserInfo,
        isSuperUser,
        fetchUserInfo,
        userInfoLoading,
        logOut,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};
