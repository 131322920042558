import React, { useCallback, useContext, useEffect, useState } from "react";
import { getFeatures } from "../api/calls";
import { UserInfoContext } from "./UserInfo";

export const FeatureContext = React.createContext();

export const WithFeatures = ({ children }) => {
  const [features, setFeatures] = useState({});
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(UserInfoContext);

  const fetchFeatures = useCallback(async () => {
    try {
      setLoading(true);
      const features = await getFeatures();

      setFeatures(features);
      setLoading(false);
    } catch (e) {
      const errorMsg = e.response.data && e.response.data.message;

      if (errorMsg) {
        alert(errorMsg);
      } else {
        alert("Unable to load features data.");
      }
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.email) {
      fetchFeatures();
    }
  }, [fetchFeatures, userInfo]);

  return <FeatureContext.Provider value={{ features, featuresLoading: loading }}>{children}</FeatureContext.Provider>;
};
