import React, { useState } from "react";
import styled from "styled-components";
import { INPUT_STATUS } from "../../shared/consts";
import { palette } from "../../components-new-design/colors";

const EditableStarRating = props => {
  const { ratingSelected, setratingSelected, stageRated } = props;
  const [hover, setHover] = useState(0);

  return (
    <div>
      {stageRated == INPUT_STATUS.PEER_AUDIO_REVIEW ? (
        <div className="star-rating">
          {[...Array(10)].map((star, index) => {
            return (
              <Btn
                i={index}
                key={index + 1}
                hoverCase={index + 1 <= hover}
                onClick={() => setratingSelected((index + 1) / 2)}
                onMouseEnter={() => setHover(index + 1)}
                onMouseLeave={() => setHover(ratingSelected)}
              >
                <i className="fa fa-star-half" aria-hidden="true"></i>
              </Btn>
            );
          })}
        </div>
      ) : (
        <div className="star-rating">
          {[...Array(5)].map((star, index) => {
            return (
              <QFBtn
                i={index}
                key={index + 1}
                hoverCase={index + 1 <= hover}
                onClick={() => setratingSelected(index + 1)}
                onMouseEnter={() => setHover(index + 1)}
                onMouseLeave={() => setHover(ratingSelected)}
              >
                <i className="fa fa-star" aria-hidden="true"></i>
              </QFBtn>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default EditableStarRating;

const Btn = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  width: 17px;
  display: inline-block;
  transform: ${props => (props.i % 2 == 0 ? "scaleX(1)" : "scaleX(-1)")};
  font-size: 25px;
  color: ${props => (props.hoverCase ? `${palette.GOLDEN_YELLOW}` : `${palette.SECONDARY_LIGHT_DARK}`)};
`;

const QFBtn = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 25px;
  color: ${props => (props.hoverCase ? `${palette.GOLDEN_YELLOW}` : `${palette.SECONDARY_LIGHT_DARK}`)};
`;
