export const palette = {
  PRIMARY: "#f2f2f2", //lightgray
  PRIMARY_DARK: "#16181d", //matteblack
  PRIMARY_LIGHT_DARK: "#22242C", //mattegray

  SECONDARY_LIGHT_DARK: "#454a56",

  GRAY: "#dddddd",
  DARK: "#22242c",
  LIGHT: "#f2f2f2",

  YELLOW: "#F2D78C",
  PURPLE: "#a68cf2",
  GREEN: "#74cf7b",
  PINK: "#F28CAF",

  BABY_PINK: "#f28caf",
  BLUE: "#8ceof2",
  ACCENT_PURPLE: "#766795",

  WARNING: "#f2do8c",
  SUCCESS: "#22242c",
  ERROR: "#ea1601",
  USERBLUE: "#766795",
  USERORANGE: "#F6942B",

  BANNER: "#b93a4c",

  GOLDEN_YELLOW: "#ffdf00",
};
