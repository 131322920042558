import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { WithUserInfo } from "./containers/UserInfo";
import { AlertContainer } from "./components-new-design/NewAlert-Component/AlertContainer";
import { WithFeatures } from "./containers/Features";

render(
  <AlertContainer>
    <WithUserInfo>
      <WithFeatures>
        <App />
      </WithFeatures>
    </WithUserInfo>
  </AlertContainer>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
