export const clearExpiredAttachments = () => {
  Object.keys(localStorage).forEach(key => {
    try {
      const currentItem = localStorage.getItem(key);
      const value = JSON.parse(currentItem);
      hasWipedExpired(key, value);
    } catch (e) {
      if (e.name !== "SyntaxError") {
        console.log(e);
      }
    }
  });
};

export const hasWipedExpired = (key, value) => {
  if (value && value.expiry && value.expiry < Date.now()) {
    localStorage.removeItem(key);
    return true;
  }
  return false;
};

export const saveRawJsonLocally = (id, rawJson, cb) => {
  // cb here will be called after the rawjson has been saved
  const now = Date.now();
  localStorage.setItem(
    id,
    JSON.stringify({
      rawJson: rawJson,
      expiry: now + 1 * 60 * 60 * 24 * 1000, // put 24 hours exipiry time for the raw jason to be read,
      last_modified_on: now,
    })
  );
  cb && cb();
};
